import React from 'react';

const UserForm = ({ 
  user, 
  orgIndex, 
  userIndex, 
  handleUserChange, 
  handleUsermailChange, 
  removeUser, 
  addUser, 
  usermailidExists,
  isLastUser,
  canRemove 
}) => {
  return (
    <div className="p-4 border border-white/20 rounded-lg bg-white/5 backdrop-blur-sm space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label htmlFor={`usertype-${orgIndex}-${userIndex}`} className="block text-sm font-medium text-white/90 mb-1">
            User Type
          </label>
          <select
            id={`usertype-${orgIndex}-${userIndex}`}
            value={user.usertype}
            onChange={(e) => handleUserChange(orgIndex, userIndex, 'usertype', e.target.value)}
            className="w-full px-3 py-2 rounded-lg border border-white/20 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 bg-white/10 backdrop-blur-sm text-black"
          >
            <option value="Admin">Admin</option>
            <option value="NetworkAdmin">Network Admin</option>
            <option value="User">User</option>
          </select>
        </div>
        <div>
          <label htmlFor={`usermailId-${orgIndex}-${userIndex}`} className="block text-sm font-medium text-white/90 mb-1">
            User Email
          </label>
          <input
            type="email"
            id={`usermailId-${orgIndex}-${userIndex}`}
            value={user.usermailId}
            onChange={(e) => handleUsermailChange(orgIndex, userIndex, e.target.value)}
            required
            className="w-full px-3 py-2 rounded-lg border border-white/20 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 bg-white/10 backdrop-blur-sm text-white placeholder-white/50"
            placeholder="Enter email address"
          />
          {usermailidExists && (
            <p className="mt-1 text-red-400 text-sm">Email already exists</p>
          )}
        </div>
      </div>
      <div className="flex justify-end space-x-2">
        {canRemove && (
          <button
            type="button"
            onClick={() => removeUser(orgIndex, userIndex)}
            className="px-3 py-1 bg-red-500/20 hover:bg-red-500/30 text-red-300 rounded-lg border border-red-500/30 transition-all duration-300"
          >
            Remove User
          </button>
        )}
        {isLastUser && (
          <button
            type="button"
            onClick={() => addUser(orgIndex)}
            className="px-3 py-1 bg-blue-500/20 hover:bg-blue-500/30 text-blue-300 rounded-lg border border-blue-500/30 transition-all duration-300"
          >
            Add User
          </button>
        )}
      </div>
    </div>
  );
};

export default UserForm;
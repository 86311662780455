import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const LoginForm = ({ onSubmit, errorMessage }) => {
  const [usermailid, setUsermailid] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e, { usermailid, password, rememberMe });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="usermailid" className="block text-sm font-medium text-white/90 mb-1">
          User Email ID
        </label>
        <input
          type="text"
          id="usermailid"
          required
          value={usermailid}
          onChange={(e) => setUsermailid(e.target.value)}
          className="w-full px-3 py-2 rounded-lg border border-white/20 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 bg-white/10 backdrop-blur-sm text-white placeholder-white/50"
          placeholder="Enter your email"
        />
      </div>

      <div>
        <label htmlFor="password" className="block text-sm font-medium text-white/90 mb-1">
          Password
        </label>
        <input
          type="password"
          id="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-3 py-2 rounded-lg border border-white/20 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 bg-white/10 backdrop-blur-sm text-white placeholder-white/50"
          placeholder="Enter your password"
        />
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="remember-me"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
            className="h-4 w-4 rounded border-white/20 bg-white/10 text-blue-500 focus:ring-blue-500"
          />
          <label htmlFor="remember-me" className="ml-2 block text-sm text-white/80">
            Remember me
          </label>
        </div>
        <Link
          to="/forgot-password"
          className="text-sm text-blue-400 hover:text-blue-300 transition-colors duration-300"
        >
          Forgot password?
        </Link>
      </div>

      {errorMessage && (
        <div className="text-red-400 text-sm bg-red-900/50 p-2 rounded-lg border border-red-500/50">
          {errorMessage}
        </div>
      )}

      <button
        type="submit"
        className="w-full bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white font-semibold py-2.5 px-4 rounded-lg transition-all duration-300 transform hover:scale-[1.02] active:scale-[0.98] shadow-lg"
      >
        Login
      </button>

      <div className="text-center mt-4">
        <p className="text-white/80 text-sm">
          Don't have an account?{' '}
          <Link 
            to="/Register" 
            className="text-blue-400 hover:text-blue-300 font-semibold transition-colors duration-300"
          >
            Register
          </Link>
        </p>
      </div>
    </form>
  );
};

export default LoginForm;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingModule from './components/LoadingModule';
import LoginForm from './components/LoginForm';

const LoginPage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e, credentials) => {
    e.preventDefault();
    setIsLoading(true);
    setLoadingMessage('Authenticating your credentials...');

    try {
      const response = await fetch('https://smartchain.gs1datakartuat.org/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;
        if (credentials.rememberMe) {
          localStorage.setItem('token', token);
          localStorage.setItem('user', credentials.usermailid);
        } else {
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('user', credentials.usermailid);
        }
        setLoadingMessage('Network Connected!');
        setTimeout(() => {
          navigate('/InputPage/Homepage');
        }, 1000);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Invalid usermailid or password');
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setErrorMessage('An error occurred during login.');
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen relative bg-gradient-to-br from-gray-900 to-gray-800 flex items-center justify-center px-4">
      {/* Blockchain Animation Background */}
      <div className="absolute inset-0 overflow-hidden opacity-10">
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1639322537228-f710d846310a')] bg-cover bg-center"></div>
      </div>

      {/* GS1 Logo */}
      <img
        src="https://www.gs1india.org/wp-content/uploads/2022/06/logo-600x402-1-600x402.png"
        alt="GS1 India Logo"
        className="absolute top-4 left-4 w-24 h-auto z-10"
      />

      {/* Main Content Container */}
      <div className="w-full max-w-6xl flex items-center justify-center gap-8 relative z-10">
        {/* Welcome Message */}
        <div className="hidden lg:block w-1/2">
          <div className="text-white space-y-6">
            <h1 className="text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
              Welcome to GS1 India Blockchain Console
            </h1>
            <p className="text-lg text-white/80 leading-relaxed">
              Access our secure blockchain network and join the future of supply chain management.
              Experience transparency, traceability, and trust like never before.
            </p>
            <div className="bg-white/10 backdrop-blur-sm rounded-lg p-4 border border-white/20">
              <p className="text-white/90">
                Already a member? Login to access our blockchain network.
                New to GS1 India? Register now to get started.
              </p>
            </div>
          </div>
        </div>

        {/* Login Form Container */}
        <div className="w-full max-w-sm">
          <div className="bg-white/10 backdrop-blur-sm rounded-2xl shadow-xl p-6 border border-white/20">
            <div className="text-center mb-6">
              <h2 className="text-2xl font-bold text-white">Login</h2>
            </div>
            <LoginForm onSubmit={handleLogin} errorMessage={errorMessage} />
          </div>
        </div>
      </div>

      {isLoading && <LoadingModule message={loadingMessage} />}
    </div>
  );
};

export default LoginPage;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, RefreshCcw, Eye, History } from 'lucide-react';
import LoadingModule from './LoadingModule';

const AnimalReport = () => {
  const [animalIdInput, setAnimalIdInput] = useState('');
  const [locationInput, setLocationInput] = useState('');
  const [tableData, setTableData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [selectedHistoryData, setSelectedHistoryData] = useState(null);
  const [accessMessage, setAccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [txData, setTxData] = useState(null);
  const [txIndex, setTxIndex] = useState(null);
  const navigate = useNavigate();
  const modalRef = useRef(null);

  useEffect(() => {
    const user = sessionStorage.getItem('user');
    if (!user) {
      setShowTimeoutModal(true);
      setTimeout(() => navigate('/login'), 2000);
      return;
    }

    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setSelectedRowData(null);
        setSelectedHistoryData(null);
        setTxData(null);
        setTxIndex(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(
        'https://smartchain.gs1datakartuat.org/blockchain/getAllAnimals', // Replace with your actual API URL
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.ok) {
        const data = await response.json();
        const animals = data.animals.map((animal) => ({
          rawData: animal.data,
          tagId: animal.data.TagId,
          animalStatus: animal.data.animalStatus,
          district: animal.data.district,
          recordedTimestamp: animal.recordedTimestamp,
        }));
        console.log("ANIMALS", animals);
        const sortedAnimals = animals.sort((a, b) => b.recordedTimestamp - a.recordedTimestamp);
        setTableData(sortedAnimals); // Store filtered data
      } else {
        const error = await response.json();
        if (error.details === 'Failed to retrieve animal data') {
          setAccessMessage(error.details);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const fetchHistory = async (tagId) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(
        `https://smartchain.gs1datakartuat.org/blockchain/getBatchHistoryByBatch/${tagId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const historyData = data.history.map(item => ({
          txId: item.txId,
          tagId: item.value.tagId,
          timestamp: new Date(item.timestamp.seconds * 1000).toLocaleString(),
          creator: item.history.creator.subject,
          mspid: item.history.mspid
        }));

        // Sort transactions by timestamp ascending
        const sortedHistoryData = historyData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

        // Assign flow numbering
        const flowData = sortedHistoryData.map((item, index) => ({
          ...item,
          flowNumber: index + 1 // Assign flow number starting from 1
        }));

        // Reverse to show the latest on top
        setSelectedHistoryData(flowData.reverse());
      }
    } catch (error) {
      console.error('Error fetching history:', error);
    }
  };

  const fetchTxData = async (txId, index) => {
    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch(
        `https://smartchain.gs1datakartuat.org/blockchain/getTxData?txId=${txId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.message === 'success') {
          setTxData(data.data);
          if(index === txIndex) {
            setTxData(null)
            setTxIndex(null);
          } 
          setTxIndex(index);
        }
      }
    } catch (error) {
      console.error('Error fetching transaction data:', error);
    }
  };


  const handleSearch = () => {
    let filteredData = [...tableData];
    
    if (animalIdInput) {
      filteredData = filteredData.filter((item) => {
        // Ensure tagId is a string before using toLowerCase
        const tagId = String(item.tagId).toLowerCase();
        return tagId.includes(animalIdInput.toLowerCase());
      });
    }
  
    if (locationInput) {
      filteredData = filteredData.filter((item) => {
        // Ensure tagId is a string before using toLowerCase
        const district = String(item.district).toLowerCase();
        return district.includes(locationInput.toLowerCase());
      });
    }
    
    setTableData(filteredData);
  };
  

  const handleReset = () => {
    setAnimalIdInput('');
    setLocationInput('');
    fetchData();
  };

  if (isLoading) {
    return <LoadingModule message="Loading animal data..." />;
  }

  return (
    <div className="p-6">
      {showTimeoutModal && (
        <LoadingModule message="Session Timed Out. Redirecting to Login Page..." />
      )}

      <div className="bg-white rounded-xl shadow-lg p-4 mb-4 max-w-4x2 mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-6">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Animal ID</label>
            <input
              type="text"
              value={animalIdInput}
              onChange={(e) => setAnimalIdInput(e.target.value)}
              placeholder="Enter Animal ID"
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">District</label>
            <input
              type="text"
              value={locationInput}
              onChange={(e) => setLocationInput(e.target.value)}
              placeholder="Enter District"
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>

          <div className="flex items-end space-x-2">
            <button
              onClick={handleSearch}
              className="flex items-center px-2 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <Search className="w-3 h-3 mr-2" />
              Search
            </button>
            <button
              onClick={handleReset}
              className="flex items-center px-2 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors"
            >
              <RefreshCcw className="w-3 h-3 mr-2" />
              Reset
            </button>
          </div>
        </div>

        {accessMessage === 'Failed to retrieve animal data' ? (
          <div className="text-center p-8 bg-red-50 rounded-lg">
            <p className="text-red-600 font-medium">
              This user does not have access to read the Animal Report.<br />
              Please contact Admin for Read Access.
            </p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {['Animal ID', 'Status', 'District', 'Recorded Timestamp', 'Action'].map((header) => (
                    <th
                      key={header}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {tableData.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.tagId}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.animalStatus}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {item.district}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {new Date(item.recordedTimestamp).toLocaleString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <button
                        onClick={() => setSelectedRowData(item.rawData)}
                        className="text-blue-600 hover:underline flex items-center"
                      >
                        <Eye className="w-4 h-4 mr-2" />
                        View
                      </button>
                      <button
                        onClick={() => fetchHistory(item.tagId)}
                        className="text-green-600 hover:text-green-800 flex items-center"
                      >
                        <History className="w-4 h-4 mr-1" />
                        History
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {selectedRowData && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div ref={modalRef} className="bg-white rounded-lg shadow-lg p-4 w-10/12 max-w-4xl max-h-[80vh] overflow-auto">
            <button
              onClick={() => setSelectedRowData(null)}
              className="fixed top-4 right-4 bg-red-400 border border-black-200 square-full shadow-lg p-0.1 hover:bg-red-300 transition"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="white"
                className="w-6 h-6 text-gray-800"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <pre className="bg-gray-50 p-4 rounded-lg overflow-auto">
              {JSON.stringify(selectedRowData, null, 2)}
            </pre>
          </div>
        </div>
      )}

{selectedHistoryData && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div ref={modalRef} className="bg-white rounded-lg shadow-lg p-6 w-10/12 max-w-4xl max-h-[80vh] overflow-auto">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Transaction History</h2>
              <button
                onClick={() => {
                  setSelectedHistoryData(null);
                  setTxData(null);
                  setTxIndex(null);
                }}
                className="fixed top-4 right-4 bg-red-400 border border-black-200 square-full shadow-lg p-0.1 hover:bg-red-300 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="white"
                  className="w-6 h-6 text-gray-800"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="space-y-6">
              {selectedHistoryData.map((history, index) => (
                <div key={index} className="relative">
                  {/* Timeline connector */}
                  {index !== selectedHistoryData.length - 1 && (
                    <div className="absolute left-[2.25rem] top-[3rem] h-auto w-0.5 bg-gradient-to-b from-blue-500 to-blue-300" />
                  )}
                  
                  <div className="flex items-start space-x-4">
                    {/* Flow number circle with gradient */}
                    <div className="relative flex-shrink-0">
                      <div className="w-10 h-10 rounded-full bg-gradient-to-br from-blue-500 to-blue-600 flex items-center justify-center shadow-lg">
                        <span className="text-white text-xl font-bold">
                          {selectedHistoryData.length - index}
                        </span>
                      </div>
                      {index === 0 && (
                        <div className="absolute -top-1 -right-1 w-5 h-5 bg-green-500 rounded-full flex items-center justify-center">
                          <span className="text-white text-xs">New</span>
                        </div>
                      )}
                    </div>

                    {/* Transaction details card */}
                    <div className="flex-grow bg-white rounded-lg shadow-md p-4 border border-gray-100 hover:shadow-lg transition-shadow duration-200">
                      <div className="space-y-2">
                        <div className="flex items-center space-x-2">
                          <h3 className="text-sm font-medium text-gray-500">Transaction ID:</h3>
                          <button
                            onClick={() => fetchTxData(history.txId, index)}
                            className="text-blue-600 hover:text-blue-800 text-sm font-mono truncate max-w-md hover:underline"
                          >
                            {history.txId}
                          </button>
                        </div>
                        <div className="flex items-center space-x-2">
                          <h3 className="text-sm font-medium text-gray-500">Timestamp:</h3>
                          <p className="text-sm text-gray-900">{history.timestamp}</p>
                        </div>
                        <div className="flex items-center space-x-2">
                          <h3 className="text-sm font-medium text-gray-500">Creator:</h3>
                          <p className="text-sm text-gray-900 font-mono">{history.creator}</p>
                        </div>
                        <div className="flex items-center space-x-2">
                          <h3 className="text-sm font-medium text-gray-500">MSPID:</h3>
                          <p className="text-sm text-gray-900 font-mono">{history.mspid}</p>
                        </div>
                      </div>
                      {txIndex === index && txData && (
              <div className="mt-4">
                <h3 className="text-sm font-medium text-gray-500 mb-2">Transaction Data</h3>
                <pre className="bg-white p-4 rounded-lg text-sm overflow-auto">
                  {JSON.stringify(txData, null, 2)}
                </pre>
              </div>
            )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnimalReport;

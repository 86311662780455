import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { 
  Home, 
  FileText, 
  Users, 
  BarChart2, 
  BarChart3,
  BarChart4,
  Database,
  LogOut,
  Menu,
  X,
  ChevronDown,
  ExternalLink
} from 'lucide-react';
import LoadingModule from './LoadingModule';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [showReportsSubmenu, setShowReportsSubmenu] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch('https://smartchain.gs1datakartuat.org/api/userdetails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ usermailid: sessionStorage.getItem('user') }),
        });

        if (response.ok) {
          const userData = await response.json();
          setUserDetails(userData);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  const handleLogout = () => {
    setIsLoggingOut(true);
    setTimeout(() => {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('user');
      navigate('/LoginPage');
    }, 1000);
  };

  const openExplorer = () => {
    window.open('http://explorer.gs1indiadatakart.org/', '_blank', 'noopener,noreferrer');
  };

  const openGrafenna = () => {
    window.open('http://grafana.gs1indiadatakart.org/', '_blank', 'noopener,noreferrer');
  };

  const NavItem = ({ to, icon: Icon, children }) => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `flex items-center p-2 rounded-lg transition-colors ${
          isActive
            ? 'bg-blue-600 text-white'
            : 'text-gray-700 hover:bg-blue-50'
        }`
      }
    >
      <Icon className="w-5 h-5" />
      {isOpen && <span className="ml-2">{children}</span>}
    </NavLink>
  );

  return (
    <aside
      className={`fixed top-0 left-0 h-full shadow-lg transition-all duration-300 z-20 ${
        isOpen ? 'w-54' : 'w-16'
      }`}
    >
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between p-4 border-b">
          {isOpen && (
            <img
              src="https://www.gs1india.org/wp-content/uploads/2022/06/logo-600x402-1-600x402.png"
              alt="GS1 India Logo"
              className="h-8"
            />
          )}
          <button
            onClick={toggleSidebar}
            className="p-1 rounded-lg hover:bg-gray-100 transition-colors"
          >
            {isOpen ? <X className="w-5 h-5" /> : <Menu className="w-5 h-5" />}
          </button>
        </div>

        <nav className="flex-1 overflow-y-auto p-2 space-y-2">
          <NavItem to="HomePage" icon={Home}>
            Home
          </NavItem>

          <NavItem to="api-docs" icon={FileText}>
            API Docs
          </NavItem>

          <div className="relative">
            <button
              onClick={() => setShowReportsSubmenu(!showReportsSubmenu)}
              className={`w-full flex items-center p-2 rounded-lg transition-colors ${
                showReportsSubmenu ? 'bg-blue-50' : 'hover:bg-blue-80'
              }`}
            >
              <BarChart4 className="w-5 h-5" />
              {isOpen && (
                <>
                  <span className="ml-2">Reports</span>
                  <ChevronDown className={`w-4 h-4 ml-auto transition-transform ${
                    showReportsSubmenu ? 'transform rotate-180' : ''
                  }`} />
                </>
              )}
            </button>

            {showReportsSubmenu && isOpen && (
              <div className="pl-4 mt-2 space-y-2">
                <NavItem to="event-report" icon={Database}>
                  Event Report
                </NavItem>
                <NavItem to="batch-report" icon={Database}>
                  Batch Report
                </NavItem>
                <NavItem to="animal-report" icon={Database}>
                  Animal Report
                </NavItem>
              </div>
            )}
          </div>

          {userDetails?.usertype === 'NetworkAdmin' && (
            <>
              <NavItem to="caliber" icon={BarChart2}>
                Caliper Benchmark
              </NavItem>
              <button
                onClick={openExplorer}
                className="w-full flex items-center p-2 rounded-lg text-gray-700 hover:bg-blue-50 transition-colors"
              >
                <ExternalLink className="w-5 h-5" />
                {isOpen && <span className="ml-2">Explorer</span>}
              </button>
              <button
                onClick={openGrafenna}
                className="w-full flex items-center p-2 rounded-lg text-gray-700 hover:bg-blue-50 transition-colors"
              >
                <BarChart3 className="w-5 h-5" />
                {isOpen && <span className="ml-2">Network Monitoring</span>}
              </button>
            </>
          )}

          {userDetails?.usertype === 'Admin' && (
            <NavItem to="add-user" icon={Users}>
              Manage Users
            </NavItem>
          )}
        </nav>

        <div className="p-2 border-t">
          <button
            onClick={handleLogout}
            className="w-full flex items-center p-2 rounded-lg text-red-600 hover:bg-red-50 transition-colors"
          >
            <LogOut className="w-5 h-5" />
            {isOpen && <span className="ml-2">Logout</span>}
          </button>
        </div>
      </div>

      {isLoggingOut && <LoadingModule message="Logging out..." />}
    </aside>
  );
};

export default Sidebar;
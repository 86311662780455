import React from 'react';

const LoadingModule = ({ message }) => {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white/10 backdrop-blur-md p-6 rounded-xl border border-white/20 shadow-2xl">
        <div className="flex flex-col items-center space-y-4">
          <div className="relative w-16 h-16">
            {/* Outer spinning ring */}
            <div className="absolute inset-0 rounded-full border-4 border-blue-500/20"></div>
            {/* Inner spinning ring */}
            <div className="absolute inset-0 rounded-full border-4 border-blue-500 border-t-transparent animate-spin"></div>
            {/* Pulsing dot */}
            <div className="absolute inset-2 rounded-full bg-blue-500/20 animate-pulse"></div>
          </div>
          <div className="text-white text-center">
            <div className="relative">
              <p className="text-lg font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600 animate-pulse">
                {message}
              </p>
              <div className="mt-2 flex space-x-1 justify-center items-center">
                <div className="w-1 h-1 bg-blue-400 rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
                <div className="w-1 h-1 bg-blue-400 rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
                <div className="w-1 h-1 bg-blue-400 rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingModule;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserPlus, UserMinus, Settings, AlertCircle } from 'lucide-react';
import LoadingModule from './LoadingModule';

const AddUser = () => {
  const [orgs, setOrgs] = useState([
    { orgpolicy: 'read', users: [{ usertype: 'User', usermailId: '', useraccess: 'Both' }] }
  ]);
  const [usermailidExists, setUsermailidExists] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [activeTab, setActiveTab] = useState('addUser');
  const [deleteEmail, setDeleteEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDeleteEmail, setConfirmDeleteEmail] = useState('');
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = sessionStorage.getItem('user');
    if (!user) {
      setShowTimeoutModal(true);
      setTimeout(() => navigate('/login'), 2000);
      return;
    }

    const fetchUserDetails = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch('https://smartchain.gs1datakartuat.org/api/userdetails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ usermailid: user }),
        });

        if (response.ok) {
          const userData = await response.json();
          setUserDetails(userData);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  const checkUsermailidExists = async (usermailid) => {
    try {
      const response = await fetch(
        `https://smartchain.gs1datakartuat.org/api/check-usermail?usermailid=${usermailid}`
      );
      return await response.json();
    } catch (error) {
      console.error('Error checking username:', error);
      return false;
    }
  };

  const handleUsermailChange = async (orgIndex, userIndex, value) => {
    const exists = await checkUsermailidExists(value);
    setUsermailidExists(exists);

    const updatedOrgs = [...orgs];
    updatedOrgs[orgIndex].users[userIndex].usermailId = value;
    setOrgs(updatedOrgs);
  };

  const handleSubmitAddUser = async (e) => {
    e.preventDefault();
    if (!userDetails) return;

    setLoadingMessage('Adding user...');
    setIsLoading(true);

    try {
      for (const org of orgs) {
        for (const user of org.users) {
          await fetch('https://smartchain.gs1datakartuat.org/api/addUserWithAttributes', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              adminEmail: userDetails.usermailid,
              newUser: {
                usermailid: user.usermailId,
                usertype: user.usertype,
                useraccess: user.useraccess,
                orgname: userDetails.orgname,
              },
            }),
          });
        }
      }

      setOrgs([
        { orgpolicy: 'read', users: [{ usertype: 'User', usermailId: '', useraccess: 'Both' }] }
      ]);
      setUsermailidExists(false);
      setLoadingMessage('User added successfully!');
    } catch (error) {
      console.error('Error adding user:', error);
      setLoadingMessage('Failed to add user. Please try again.');
    }
  };

  const handleSubmitModifyUserAccess = async (e) => {
    e.preventDefault();
    if (!userDetails) return;

    setLoadingMessage('Updating user access...');
    setIsLoading(true);

    try {
      for (const org of orgs) {
        for (const user of org.users) {
          await fetch('https://smartchain.gs1datakartuat.org/api/updateUserType', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              adminEmail: userDetails.usermailid,
              department: user.usermailId,
              userorg: userDetails.orgname,
              newUserAccess: user.useraccess,
            }),
          });
        }
      }

      setOrgs([
        { orgpolicy: 'read', users: [{ usertype: 'User', usermailId: '', useraccess: 'Both' }] }
      ]);
      setLoadingMessage('User access updated successfully!');
    } catch (error) {
      console.error('Error updating user access:', error);
      setLoadingMessage('Failed to update user access. Please try again.');
    }
  };

  const handleDeleteUser = async () => {
    if (!userDetails || !confirmDeleteEmail) return;

    setShowConfirmDialog(false);
    setIsLoading(true);
    setLoadingMessage('Deleting user...');

    try {
      await fetch('https://smartchain.gs1datakartuat.org/api/deleteUser', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          adminEmail: userDetails.usermailid,
          usermailid: confirmDeleteEmail,
        }),
      });

      setLoadingMessage(`User ${confirmDeleteEmail} deleted successfully!`);
      setDeleteEmail('');
    } catch (error) {
      console.error('Error deleting user:', error);
      setLoadingMessage(`Failed to delete user ${confirmDeleteEmail}. Please try again.`);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      {showTimeoutModal && <LoadingModule message="Session Timed Out. Redirecting to Login Page..." />}

      <div className="bg-white rounded-xl shadow-lg p-6 w-8/12 mx-auto  my-auto">
      <div className="flex justify-center space-x-4 mb-8">
          <button
            onClick={() => setActiveTab('addUser')}
            className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'addUser'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
          >
            <UserPlus className="w-5 h-5 mr-2" />
            Add User
          </button>
          <button
            onClick={() => setActiveTab('deleteUser')}
            className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'deleteUser'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
          >
            <UserMinus className="w-5 h-5 mr-2" />
            Delete User
          </button>
          <button
            onClick={() => setActiveTab('modifyUserAccess')}
            className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'modifyUserAccess'
                ? 'bg-blue-600 text-white'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
          >
            <Settings className="w-5 h-5 mr-2" />
            Modify Access
          </button>
        </div>

        {activeTab === 'addUser' && (
          <form onSubmit={handleSubmitAddUser} className="space-y-6">
            {orgs.map((org, orgIndex) => (
              <div key={orgIndex}>
                {org.users.map((user, userIndex) => (
                  <div key={userIndex} className="bg-gray-50 rounded-lg p-6 space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        User Email
                      </label>
                      <input
                        type="email"
                        value={user.usermailId}
                        onChange={(e) => handleUsermailChange(orgIndex, userIndex, e.target.value)}
                        className="w-full h-10 rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        required
                      />
                      {usermailidExists && (
                        <p className="mt-1 text-sm text-red-600">Email already exists</p>
                      )}
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Access Level
                      </label>
                      <select
                        value={user.useraccess}
                        onChange={(e) => {
                          const updatedOrgs = [...orgs];
                          updatedOrgs[orgIndex].users[userIndex].useraccess = e.target.value;
                          setOrgs(updatedOrgs);
                        }}
                        className="w-full h-10 rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        required
                      >
                        <option value="Both">Both</option>
                        <option value="Read">Read</option>
                        <option value="Write">Write</option>
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        User Type
                      </label>
                      <select
                        value={user.usertype}
                        onChange={(e) => {
                          const updatedOrgs = [...orgs];
                          updatedOrgs[orgIndex].users[userIndex].usertype = e.target.value;
                          setOrgs(updatedOrgs);
                        }}
                        className="w-full h-10 rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        required
                      >
                        <option value="User">User</option>
                        <option value="Admin">Admin</option>
                        <option value="NetworkAdmin">NetworkAdmin</option>
                      </select>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-blue-600 text-white px-4 py-2 rounded-lg"
              >
                Add User
              </button>
            </div>
          </form>
        )}

        {activeTab === 'deleteUser' && (
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Delete User</label>
              <input
                type="email"
                placeholder="Enter email to delete"
                value={deleteEmail}
                onChange={(e) => setDeleteEmail(e.target.value)}
                className="w-full h-10 rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setShowConfirmDialog(true)}
                className="bg-red-600 text-white px-4 py-2 rounded-lg"
              >
                Delete User
              </button>
            </div>
          </div>
        )}

        {activeTab === 'modifyUserAccess' && (
          <form onSubmit={handleSubmitModifyUserAccess} className="space-y-6">
            {orgs.map((org, orgIndex) => (
              <div key={orgIndex}>
                {org.users.map((user, userIndex) => (
                  <div key={userIndex} className="bg-gray-50 rounded-lg p-6 space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">User Email</label>
                      <input
                        type="email"
                        value={user.usermailId}
                        onChange={(e) => handleUsermailChange(orgIndex, userIndex, e.target.value)}
                        className="w-full h-10 rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        required
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Access Level
                      </label>
                      <select
                        value={user.useraccess}
                        onChange={(e) => {
                          const updatedOrgs = [...orgs];
                          updatedOrgs[orgIndex].users[userIndex].useraccess = e.target.value;
                          setOrgs(updatedOrgs);
                        }}
                        className="w-full h-10 rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        required
                      >
                        <option value="Both">Both</option>
                        <option value="Read">Read</option>
                        <option value="Write">Write</option>
                      </select>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-blue-600 text-white px-4 py-2 rounded-lg"
              >
                Modify Access
              </button>
            </div>
          </form>
        )}
      </div>

      {showConfirmDialog && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 space-y-4">
            <h3 className="text-xl font-semibold">Are you sure you want to delete this user?</h3>
            <div className="flex justify-end">
              <button
                onClick={handleDeleteUser}
                className="bg-red-600 text-white px-4 py-2 rounded-lg mr-4"
              >
                Yes
              </button>
              <button
                onClick={() => setShowConfirmDialog(false)}
                className="bg-gray-300 text-black px-4 py-2 rounded-lg"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddUser;

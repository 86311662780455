import React, { useState, useEffect } from 'react';
import { Activity, Building2, UserCircle2, Network, Briefcase, Database, TrendingUp, Users, FileCheck2, AlertCircle } from 'lucide-react';
import LoadingModule from './LoadingModule';

const HomePage = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [selectedBox, setSelectedBox] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch('https://smartchain.gs1datakartuat.org/api/userdetails', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ usermailid: sessionStorage.getItem('user') }),
        });

        if (response.ok) {
          const userData = await response.json();
          setUserDetails(userData);
        } else {
          console.error('Failed to fetch user details');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  const handleBoxClick = (box) => {
    setSelectedBox(box === selectedBox ? null : box);
  };

  if (isLoading) {
    return <LoadingModule message="Loading user details..." />;
  }

  const stats = [
    { id: 'transactions', label: 'Total Transactions', value: '1,234', icon: TrendingUp, color: 'emerald' },
    { id: 'users', label: 'Active Users', value: '56', icon: Users, color: 'blue' },
    { id: 'contracts', label: 'Smart Contracts', value: '1', icon: FileCheck2, color: 'purple' },
    { id: 'incidents', label: 'Network Status', value: 'Healthy', icon: AlertCircle, color: 'green' },
  ];

  return (
    <div className="p-8">
      {/* Welcome Banner */}
      <div className="mb-8 bg-gradient-to-r from-blue-600 to-indigo-600 rounded-2xl shadow-lg">
        <div className="px-8 py-12 text-white">
          <h1 className="text-4xl font-bold mb-4">Welcome to Blockchain Network</h1>
          <p className="text-blue-100 text-lg">
            Manage your blockchain operations and monitor network activities in real-time
          </p>
        </div>
      </div>

      {/* Statistics Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {stats.map((stat) => (
          <div
            key={stat.id}
            className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow"
          >
            <div className="flex items-center">
              <div className={`p-3 rounded-lg bg-${stat.color}-100`}>
                <stat.icon className={`w-6 h-6 text-${stat.color}-600`} />
              </div>
              <div className="ml-4">
                <p className="text-sm text-gray-500">{stat.label}</p>
                <p className="text-2xl font-semibold text-gray-900">{stat.value}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* User Details Section */}
      {userDetails && (
        <div className="bg-white rounded-xl shadow-lg p-8">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-2xl font-bold text-gray-800">User Information</h2>
            <Activity className="w-6 h-6 text-blue-600" />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              { id: 'usermailid', label: 'User Email', value: userDetails.usermailid, icon: UserCircle2, color: 'blue' },
              { id: 'orgname', label: 'Organization Name', value: userDetails.orgname, icon: Building2, color: 'indigo' },
              { id: 'orgid', label: 'Organization ID', value: userDetails.orgid, icon: Briefcase, color: 'purple' },
              { id: 'usertype', label: 'User Type', value: userDetails.usertype, icon: UserCircle2, color: 'pink' },
              { id: 'networkid', label: 'Network ID', value: userDetails.networkid, icon: Network, color: 'cyan' },
              { id: 'projectname', label: 'Project Name', value: userDetails.projectname, icon: Database, color: 'teal' },
            ].map((box) => (
              <div
                key={box.id}
                className={`transform transition-all duration-300 ${
                  selectedBox === box.id ? 'scale-105' : ''
                }`}
                onClick={() => handleBoxClick(box.id)}
              >
                <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md cursor-pointer border border-gray-100">
                  <div className="flex items-center">
                    <div className={`p-3 rounded-lg bg-${box.color}-50`}>
                      <box.icon className={`w-5 h-5 text-${box.color}-600`} />
                    </div>
                    <div className="ml-4">
                      <p className="text-sm text-gray-500">{box.label}</p>
                      <p className="text-lg font-semibold text-gray-900">{box.value}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePage;
import React from 'react';

const Caliber = () => {
  return (
    <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
      <iframe
        src={`${process.env.PUBLIC_URL}/caliber/report.html`}
        title="Caliber Report"
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </div>
  );
};

export default Caliber;

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import LoadingModule from './components/LoadingModule';
import OrganizationForm from './components/OrganizationForm';

const RegistrationPage = () => {
  const [projectname, setProjectName] = useState('');
  const [orgs, setOrgs] = useState([{ 
    orgname: '', 
    orgpolicy: 'read', 
    users: [{ usertype: 'User', username: '', usermailId: '', password: '' }] 
  }]);
  const [gs1Org, setGs1Org] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [usermailidExists, setUsermailidExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const navigate = useNavigate();

  const checkUsermailidExists = async (usermailid) => {
    try {
      const response = await axios.get('https://smartchain.gs1datakartuat.org/api/check-usermail', {
        params: { usermailid },
      });
      return response.data;
    } catch (error) {
      console.error('Error checking username:', error);
      return false;
    }
  };

  const addOrg = () => {
    setOrgs([...orgs, { 
      orgname: '', 
      orgpolicy: 'read', 
      users: [{ usertype: 'User', username: '', usermailId: '', password: '' }] 
    }]);
  };

  const removeOrg = (index) => {
    if (orgs.length > 1) {
      setOrgs(orgs.filter((_, i) => i !== index));
    }
  };

  const handleOrgChange = (index, field, value) => {
    const updatedOrgs = orgs.map((org, i) => 
      i === index ? { ...org, [field]: value } : org
    );
    setOrgs(updatedOrgs);
  };

  const addUser = (orgIndex) => {
    const updatedOrgs = orgs.map((org, i) => 
      i === orgIndex 
        ? { 
            ...org, 
            users: [...org.users, { usertype: 'User', username: '', usermailId: '', password: '' }] 
          } 
        : org
    );
    setOrgs(updatedOrgs);
  };

  const removeUser = (orgIndex, userIndex) => {
    if (orgs[orgIndex].users.length > 1) {
      const updatedOrgs = orgs.map((org, i) => 
        i === orgIndex 
          ? { 
              ...org, 
              users: org.users.filter((_, j) => j !== userIndex) 
            } 
          : org
      );
      setOrgs(updatedOrgs);
    }
  };

  const handleUserChange = (orgIndex, userIndex, field, value) => {
    const updatedOrgs = orgs.map((org, i) => 
      i === orgIndex 
        ? {
            ...org, 
            users: org.users.map((user, j) => 
              j === userIndex ? { ...user, [field]: value } : user
            )
          } 
        : org
    );
    setOrgs(updatedOrgs);
  };

  const handleUsermailChange = async (orgIndex, userIndex, value) => {
    const exists = await checkUsermailidExists(value);
    setUsermailidExists(exists);
    handleUserChange(orgIndex, userIndex, 'usermailId', value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (usermailidExists) {
      return;
    }

    setIsLoading(true);
    setLoadingMessage('Creating your network...');

    try {
      const response = await axios.post('https://smartchain.gs1datakartuat.org/api/register', 
        { projectname, orgs, gs1Org },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.status === 201) {
        setLoadingMessage('Network created successfully!');
        setTimeout(() => {
          setShowModal(false);
          setIsLoading(false);
          navigate('/LoginPage');
        }, 1500);
      }
    } catch (error) {
      console.error('Error registering:', error.response?.data);
      setLoadingMessage('Registration failed. Please try again.');
      setTimeout(() => setIsLoading(false), 1000);
    }
  };

  return (
    <div className="min-h-screen relative bg-gradient-to-br from-gray-900 to-gray-800 py-8 px-4">
      {/* Blockchain Animation Background */}
      <div className="absolute inset-0 overflow-hidden opacity-10">
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1639322537228-f710d846310a')] bg-cover bg-center"></div>
      </div>

      {/* GS1 Logo */}
      <img
        src="https://www.gs1india.org/wp-content/uploads/2022/06/logo-600x402-1-600x402.png"
        alt="GS1 India Logo"
        className="absolute top-4 left-4 w-24 h-auto z-10"
      />

      {/* Registration Container */}
      <div className="max-w-3xl mx-auto relative z-10">
        <div className="bg-white/10 backdrop-blur-sm rounded-2xl shadow-xl p-6 border border-white/20">
          <div className="text-center mb-6">
            <h2 className="text-2xl font-bold text-white">Register</h2>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="projectname" className="block text-sm font-medium text-white/90 mb-1">
                Project Name
              </label>
              <input
                type="text"
                id="projectname"
                value={projectname}
                onChange={(e) => setProjectName(e.target.value)}
                required
                className="w-full px-3 py-2 rounded-lg border border-white/20 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 bg-white/10 backdrop-blur-sm text-white placeholder-white/50"
                placeholder="Enter project name"
              />
            </div>

            {orgs.map((org, index) => (
              <OrganizationForm
                key={index}
                org={org}
                orgIndex={index}
                handleOrgChange={handleOrgChange}
                removeOrg={removeOrg}
                addOrg={addOrg}
                handleUserChange={handleUserChange}
                handleUsermailChange={handleUsermailChange}
                removeUser={removeUser}
                addUser={addUser}
                usermailidExists={usermailidExists}
              />
            ))}

            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="gs1Org"
                checked={gs1Org}
                onChange={(e) => setGs1Org(e.target.checked)}
                className="h-4 w-4 rounded border-white/20 bg-white/10 text-blue-500 focus:ring-blue-500"
              />
              <label htmlFor="gs1Org" className="text-sm text-white/90">
                Allow GS1 as Default Organization
              </label>
            </div>

            <button
              type="submit"
              className="w-full bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white font-semibold py-2.5 px-4 rounded-lg transition-all duration-300 transform hover:scale-[1.02] active:scale-[0.98] shadow-lg"
            >
              Register
            </button>

            <div className="text-center">
              <p className="text-white/80 text-sm">
                Already have an account?{' '}
                <Link 
                  to="/LoginPage" 
                  className="text-blue-400 hover:text-blue-300 font-semibold transition-colors duration-300"
                >
                  Login
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>

      {isLoading && <LoadingModule message={loadingMessage} />}
    </div>
  );
};

export default RegistrationPage;
import React from 'react';
import UserForm from './UserForm';

const OrganizationForm = ({ org, orgIndex, handleOrgChange, removeOrg, addOrg, handleUserChange, handleUsermailChange, removeUser, addUser, usermailidExists }) => {
  return (
    <div className="space-y-4 mb-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label htmlFor={`orgname-${orgIndex}`} className="block text-sm font-medium text-white/90 mb-1">
            Organization Name
          </label>
          <input
            type="text"
            id={`orgname-${orgIndex}`}
            value={org.orgname}
            onChange={(e) => handleOrgChange(orgIndex, 'orgname', e.target.value)}
            required
            className="w-full px-3 py-2 rounded-lg border border-white/20 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 bg-white/10 backdrop-blur-sm text-white placeholder-white/50"
            placeholder="Enter organization name"
          />
        </div>
        <div>
          <label htmlFor={`orgpolicy-${orgIndex}`} className="block text-sm font-medium text-white/90 mb-1">
            Organization Policy
          </label>
          <select
            id={`orgpolicy-${orgIndex}`}
            value={org.orgpolicy}
            onChange={(e) => handleOrgChange(orgIndex, 'orgpolicy', e.target.value)}
            className="w-full px-3 py-2 rounded-lg border border-white/20 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 bg-white/10 backdrop-blur-sm text-black"
          >
            <option value="read">Read</option>
            <option value="write">Write</option>
            <option value="both">Both</option>
          </select>
        </div>
      </div>

      {org.users.map((user, userIndex) => (
        <UserForm
          key={userIndex}
          user={user}
          orgIndex={orgIndex}
          userIndex={userIndex}
          handleUserChange={handleUserChange}
          handleUsermailChange={handleUsermailChange}
          removeUser={removeUser}
          addUser={addUser}
          usermailidExists={usermailidExists}
          isLastUser={userIndex === org.users.length - 1}
          canRemove={userIndex > 0}
        />
      ))}

      <div className="flex justify-end space-x-2">
        {orgIndex > 0 && (
          <button
            type="button"
            onClick={() => removeOrg(orgIndex)}
            className="px-4 py-2 bg-red-500/20 hover:bg-red-500/30 text-red-300 rounded-lg border border-red-500/30 transition-all duration-300"
          >
            Remove Organization
          </button>
        )}
        <button
          type="button"
          onClick={addOrg}
          className="px-4 py-2 bg-blue-500/20 hover:bg-blue-500/30 text-blue-300 rounded-lg border border-blue-500/30 transition-all duration-300"
        >
          Add Organization
        </button>
      </div>
    </div>
  );
};

export default OrganizationForm;